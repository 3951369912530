import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import IntroCopy from "../components/IntroCopy";
import ImageGallery from "../components/ImageGallery";

const SuccessPage = ({ data }) => {
  return (
    <Layout showContactForm={false}>
      <main>
        <SEO title="Success" />
        <IntroCopy
          headline="Thanks for getting in touch!"
          body="We will contact you as soon as we can regarding your query."
          topPadding
        />
        {/* <ImageGallery
          images={data.images.nodes.filter(image => image.name === "1")}
        /> */}
      </main>
    </Layout>
  );
};

export const query = graphql`
  query SuccessImages {
    images: allFile(filter: { relativeDirectory: { eq: "contact" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default SuccessPage;
